import { Box } from '@thepuzzlers/pieces';

export const SpringLineThree = ({ sx, ...props }) => {
  return (
    <Box
      as="svg"
      className="spring-line-three"
      viewBox="0 0 305 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: '100%',
        color: 'red',
        ...sx
      }}
      {...props}>
      <path
        d="M1 16.1872C10.3294 8.98666 21.4186 4.09799 33.014 2.05101C36.9916 1.35263 41.138 0.991401 45.0193 2.09918C48.9005 3.20696 52.4924 6.02456 53.5531 9.92587C54.6138 13.8272 52.4442 18.5954 48.5148 19.5346C44.5853 20.4738 40.1738 16.2595 41.6202 12.4786C42.3434 10.6002 44.1273 9.34789 45.863 8.33645C55.9156 2.53266 68.2824 0.89507 79.5163 3.83309C84.4582 5.13352 89.1349 7.25275 93.9322 8.96258C103.454 12.3582 113.555 14.0921 123.68 14.0921C126.645 14.0921 129.803 13.8994 132.238 12.1896C134.673 10.4798 135.926 6.79519 134.094 4.45922C131.925 1.42488 127.585 0.172606 124.114 1.56937C120.643 2.96613 118.425 6.9156 118.979 10.6002C119.919 16.7893 127.2 19.8477 133.468 19.9922C149.378 20.3293 163.24 9.66096 178.572 5.49476C183.706 4.09799 189.034 3.44778 194.362 3.52002C197.375 3.56819 200.509 3.90534 203.04 5.51884C205.571 7.13234 207.331 10.3112 206.415 13.177C205.499 16.0427 201.28 17.4876 199.255 15.2962C197.785 10.2389 204.125 6.6507 209.212 5.20577C217.119 2.94205 225.894 0.726497 233.27 4.31473C236.211 5.75966 238.67 7.99929 241.539 9.58871C246.071 12.0933 251.399 12.7916 256.582 12.8639C260.174 12.912 263.982 12.6471 266.948 10.6483C269.913 8.64951 271.649 4.3629 269.744 1.30447C266.297 2.84572 266.466 8.2642 269.19 10.8891C271.914 13.5141 275.964 14.0921 279.724 14.5255C287.921 15.4888 296.647 16.3558 304 12.6231"
        stroke="#E6592C"
        stroke-miterlimit="10"
      />
    </Box>
  );
};
