import { AspectRatioImageContainer } from 'components';
import { Section, Heading } from '@thepuzzlers/pieces';
import { convertApiHtmlText } from 'graphqlHooks/helper';
import { SpringLineThree } from 'components/svgs/SpringLineThree';

export const CompanyImpact = ({ data }) => {
  return (
    <Section className="company-impact" sx={{ position: 'relative' }}>
      <ImageBlock image={data?.image} />
      <DescriptionBlock description={data?.description} />
      <Decoration />
    </Section>
  );
};

const ImageBlock = ({ image }) => {
  return (
    <AspectRatioImageContainer
      src={image?.image}
      alt={image?.alt}
      aspectRatios={0.7}
      className="__image-block"
      sx={{
        gridColumn: [
          '2/13',
          null,
          '1/span 11',
          null,
          '1/span 11',
          '1/ span 10'
        ],
        mb: [0, null, '8.5rem', null, 0, 0]
      }}
    />
  );
};

const DescriptionBlock = ({ description }) => {
  return (
    <Heading
      as="h2"
      className="__description-block"
      sx={{
        alignSelf: ['start', null, 'end', null, 'center', 'center'],
        gridColumn: ['1/13', null, '12/25', null, '12/25', '12/ span 12'],
        fontFamily: 'primary.normal',
        lineHeight: 1.35,
        fontSize: ['2.8rem', null, '3.2rem', null, '4rem', '4.8rem'],
        mt: ['4rem', null, 0, null, 0, 0],
        '& span': {
          fontFamily: 'primary.bold'
        }
      }}
      dangerouslySetInnerHTML={{ __html: convertApiHtmlText(description.html) }}
    />
  );
};

const Decoration = () => {
  return (
    <SpringLineThree
      sx={{
        position: 'absolute',
        width: ['22.8rem', null, '22.8rem', null, '24.4rem', '30.3rem'],
        gridColumn: ['1 / 13', null, '1 / 25', null, '1 / 25', '1 / 25'],
        bottom: ['-14rem', null, '-16rem', null, '-8rem', '-6.4rem'],
        left: ['-10.6rem', null, 0, null, 0, 0]
      }}
    />
  );
};
